var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("router-link", { attrs: { to: "/home/HomePage" } }, [
            _c("img", {
              staticStyle: { width: "112px", height: "40px" },
              attrs: { src: require("@/assets/login-logo.png"), alt: "" },
            }),
          ]),
          _c("span", { staticStyle: { color: "#dddddd" } }, [_vm._v("|")]),
          _c("span", { staticClass: "logo-name" }, [_vm._v("会员登录")]),
          _vm.compatible
            ? _c(
                "router-link",
                {
                  staticClass: "header-right",
                  attrs: { to: "/home/HomePage" },
                },
                [
                  _vm._v("返回商城 "),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.compatible
        ? _c("div", { staticClass: "mainCompatible" }, [
            _c("p", { staticClass: "font-mainCompatible" }, [
              _vm._v("请使用谷歌、火狐、dge或是360浏览器极速模式访问"),
            ]),
          ])
        : _vm._e(),
      _vm.compatible
        ? _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "main-left" }, [
              _vm._m(0),
              _c("div", { key: "login", staticClass: "login" }, [
                _c("span", { staticClass: "login-title" }, [
                  _vm._v("欢迎登录"),
                ]),
                _c(
                  "div",
                  { staticClass: "login-main" },
                  [
                    !_vm.CFCAOn
                      ? [
                          _vm.operatingStatus === "login"
                            ? _c(
                                "el-form",
                                {
                                  ref: "ruleForm",
                                  attrs: {
                                    model: _vm.ruleForm,
                                    rules: _vm.rules,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "nickname" } },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请输入登录名",
                                            disabled: _vm.CFCAOn,
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.login("ruleForm")
                                            },
                                          },
                                          model: {
                                            value: _vm.ruleForm.nickname,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "nickname",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.nickname",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              padding: "0 10px",
                                              "padding-top": "14px",
                                            },
                                            attrs: {
                                              slot: "prefix",
                                              src: require("@/assets/name.png"),
                                              alt: "",
                                            },
                                            slot: "prefix",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "password" } },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            placeholder: "请输入密码",
                                            "show-password": "",
                                            disabled: _vm.CFCAOn,
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              )
                                                return null
                                              return _vm.login("ruleForm")
                                            },
                                          },
                                          model: {
                                            value: _vm.ruleForm.password,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.password",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              padding: "0 10px",
                                              "padding-top": "14px",
                                            },
                                            attrs: {
                                              slot: "prefix",
                                              src: require("../../assets/logo-password.png"),
                                              alt: "",
                                            },
                                            slot: "prefix",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "picCode" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-item image" },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              "margin-right": "20px",
                                            },
                                            attrs: {
                                              placeholder: "图形验证码",
                                              disabled: _vm.CFCAOn,
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.login("ruleForm")
                                              },
                                            },
                                            model: {
                                              value: _vm.ruleForm.picCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm,
                                                  "picCode",
                                                  $$v
                                                )
                                              },
                                              expression: "ruleForm.picCode",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "figure_code",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.getImgCode()
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "data:image/png;base64," +
                                                    _vm.imagebase,
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "login-button",
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.login("ruleForm")
                                            },
                                          },
                                        },
                                        [_vm._v("登 录 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.operatingStatus === "login"
                            ? _c(
                                "div",
                                { staticClass: "login-foot" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/home/UserRegister" } },
                                    [
                                      _c("span", { staticClass: "foot-item" }, [
                                        _vm._v("立即注册"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 10px" } },
                                    [_vm._v("|")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "foot-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push(
                                            "/ForgetPassword"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("忘记密码")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.operatingStatus === "forget"
                            ? _c("div", { staticClass: "login-foot" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#a7b1c1",
                                      "font-size": "14px",
                                    },
                                  },
                                  [_vm._v("已有账号，")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "foot-item foot-color",
                                    on: {
                                      click: function ($event) {
                                        _vm.operatingStatus = "login"
                                      },
                                    },
                                  },
                                  [_vm._v("马上登录")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c("img", {
                                staticStyle: { margin: "40px 0 30px" },
                                attrs: {
                                  src: require("@/assets/CFCA.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "p",
                                { staticStyle: { "font-size": "18px" } },
                                [_vm._v("安装铝拍拍平台登录控件")]
                              ),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    color: "blue",
                                    "font-size": "16px",
                                    "line-height": "40px",
                                    "margin-bottom": "30px",
                                  },
                                },
                                [_vm._v("安装后，请刷新页面")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "login-button",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.download },
                                },
                                [_vm._v("下载登录控件")]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            "show-close": false,
            width: "380px",
            top: "34vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "success-box" },
            [
              _c("i", { staticClass: "el-icon-success" }),
              _c("p", { staticClass: "success-title" }, [
                _vm._v("密码重置成功"),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "success-btn",
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.toLogin()
                    },
                  },
                },
                [_vm._v("马上登录")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("Foot"),
      _c("CFCA", {
        ref: "CFCARef",
        attrs: { id: "CFCAId" },
        on: {
          getBrowser: _vm.getBrowser,
          getCertInfo: function ($event) {
            _vm.certInfo = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-bg" }, [
      _c("img", { attrs: { src: require("@/assets/login-bg.jpg"), alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }