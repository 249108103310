<template>
  <div class="wrap">
    <div class="header">
      <router-link to="/home/HomePage">
        <img src="@/assets/login-logo.png" style="width:112px;height:40px" alt="" />
      </router-link>
      <span style="color: #dddddd">|</span>
      <span class="logo-name">会员登录</span>
      <router-link to="/home/HomePage" class="header-right" v-if="compatible">返回商城 <i class="el-icon-arrow-right"></i>
      </router-link>
    </div>
    <div class="mainCompatible" v-if="!compatible">
      <p class="font-mainCompatible">请使用谷歌、火狐、dge或是360浏览器极速模式访问</p>
    </div>
    <div class="main" v-if="compatible">
      <div class="main-left">
        <div class="main-bg">
          <img src="@/assets/login-bg.jpg" alt="" />
        </div>
        <div class="login" key="login">
          <span class="login-title">欢迎登录</span>

          <div class="login-main">
            <!-- 登录 -->
            <template v-if="!CFCAOn">
              <el-form v-if="operatingStatus === 'login'" :model="ruleForm" :rules="rules" ref="ruleForm">

                <el-form-item prop="nickname">
                  <el-input placeholder="请输入登录名" v-model="ruleForm.nickname" @keyup.enter.native="login('ruleForm')"
                    :disabled='CFCAOn'>
                    <img slot="prefix" style="padding: 0 10px; padding-top: 14px" src="@/assets/name.png" alt="" />
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input placeholder="请输入密码" v-model="ruleForm.password" show-password
                    @keyup.enter.native="login('ruleForm')" :disabled='CFCAOn'>
                    <img slot="prefix" style="padding: 0 10px; padding-top: 14px" src="../../assets/logo-password.png"
                      alt="" />
                  </el-input>
                </el-form-item>
                <el-form-item prop="picCode">
                  <div class="form-item image">
                    <el-input placeholder="图形验证码" v-model="ruleForm.picCode" style="margin-right: 20px"
                      @keyup.enter.native="login('ruleForm')" :disabled='CFCAOn'></el-input>
                    <div class="figure_code" @click="getImgCode()">
                      <img v-bind:src="'data:image/png;base64,' + imagebase" />
                    </div>
                  </div>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" class="login-button" @click="login('ruleForm')">登 录 </el-button>
                </el-form-item>
              </el-form>

              <!-- 登录底部文字 -->
              <div v-if="operatingStatus === 'login'" class="login-foot">
                <router-link to="/home/UserRegister">
                  <span class="foot-item">立即注册</span>
                </router-link>
                <span style="margin: 0 10px">|</span>
                <span @click="$router.push('/ForgetPassword')" class="foot-item">忘记密码</span>
              </div>
              <!-- 找回密码底部文字 -->
              <div v-if="operatingStatus === 'forget'" class="login-foot">
                <span style="color: #a7b1c1; font-size: 14px">已有账号，</span>
                <span @click="operatingStatus = 'login'" class="foot-item foot-color">马上登录</span>
              </div>

            </template>
            <template v-else>
              <div style="text-align:center">
                <img src="@/assets/CFCA.png" style="margin:40px 0 30px" alt="">
                <p style="font-size:18px">安装铝拍拍平台登录控件</p>
                <p style="color:blue;font-size:16px;line-height:40px;margin-bottom:30px">安装后，请刷新页面</p>
                <el-button type="primary" class="login-button" @click="download">下载登录控件</el-button> 
              </div>

            </template>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" :show-close="false" width="380px" top="34vh">
      <div class="success-box">
        <i class="el-icon-success"></i>
        <p class="success-title">密码重置成功</p>
        <el-button plain class="success-btn" @click="toLogin()">马上登录</el-button>
      </div>
    </el-dialog>
    <Foot></Foot>

    <CFCA id="CFCAId" ref="CFCARef" @getBrowser='getBrowser' @getCertInfo="certInfo = $event" />
  </div>
</template>

<script>
  import {
    getCode,
    updatePwdByTel,
    verifyCode,
    selectCountByTel,
    login,
    getWXImgCode,
    getPermission,
    selectAccountTypeByLoginName
  } from './api';
  import CFCA from '../CFCA/CFCA';
  import Foot from '@/components/Foot';
  export default {
    mounted() {
      //获取图形验证码
      this.BrowserType();
      this.getImgCode();
    },
    components: {
      CFCA,
      Foot
    },
    data() {
      var validatePhone = (rule, value, callback) => {
        console.log(value);
        if (!value) {
          return callback(new Error('请输入手机号码'));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
          if (reg.test(value)) {
            return callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      var validatePass1 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleFormReset.password2 !== '') {
            this.$refs.ruleFormReset.validateField('password2');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleFormReset.password1) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var validatepassword1 = (rule, value, callback) => {
        var ok = true;
        var pwd = true;
        const FloatRegex = /^[A-Za-z0-9~@#$*]*$/; //只能包含（~@#$*）
        const FloatRegex2 = /^.{6,8}$/; //6-8
        const FloatRegex3 = /^[^\u4e00-\u9fa5]+$/; //不能中文
        const FloatRegex4 = /[A-Z]+/; //必须包含大写字母
        const FloatRegex5 = /[a-z]+/; //必须包含小写字母
        const FloatRegex6 = /[0-9]+/; //必须包含小写字母

        if (!FloatRegex.test(value)) {
          ok = false;
          callback(new Error('不能出现特殊字符,只能包含（~@#$*）'));
        }
        if (!FloatRegex2.test(value)) {
          ok = false;
          callback(new Error('长度6-8'));
        }

        if (!FloatRegex3.test(value)) {
          ok = false;
          callback(new Error('不能出现中文'));
        }
        if (!FloatRegex4.test(value)) {
          ok = false;
          pwd = false;
        }
        if (!FloatRegex5.test(value)) {
          ok = false;
          pwd = false;
          // callback(new Error("必须包含小写字母"))
        }
        if (!FloatRegex6.test(value)) {
          ok = false;
          pwd = false;
          // callback(new Error("必须包含数字"))
        }
        if (!pwd) {
          callback(new Error('必须包含大、小写字母和数字'));
        }

        if (ok) {
          callback();
        }
      };
      return {
        CFCAOn: false,
        compatible: true,
        dialogVisible: false,
        getCodeShow: true,
        count: 60,
        operatingStatus: 'login',
        resubmit: true,
        ruleForm: {
          nickname: '',
          password: '',
          picCode: ''
        },
        rules: {
          nickname: [{
            required: true,
            message: '请输入登录名',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }],
          picCode: [{
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur'
          }]
        },
        ruleFormForget: {
          phone: '',
          picCode: '',
          phonePicCode: ''
        },
        rulesForget: {
          phone: [{
            validator: validatePhone,
            trigger: 'blur'
          }],
          picCode: [{
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur'
          }],
          phonePicCode: [{
            required: true,
            message: '请输入手机验证码',
            trigger: 'blur'
          }]
        },
        ruleFormReset: {
          password1: '',
          password2: ''
        },
        rulesReset: {
          password1: [{
              required: true,
              validator: validatePass1,
              trigger: 'blur'
            },
            {
              required: false,
              validator: validatepassword1,
              trigger: 'blur'
            }
          ],
          password2: [{
              required: true,
              validator: validatePass2,
              trigger: 'blur'
            },
            {
              required: false,
              validator: validatepassword1,
              trigger: 'blur'
            }
          ]
        },
        phoneCodeUUID: '',
        imgUuid: '',
        imagebase: ''
      };
    },
    methods: {
      // 获取插件对应版本URL
      getBrowser(url) {
        this.CFCAOn = true
      },

      // 下载CFCA插件
      download() {
        // console.log(this.$refs.CFCARef.download)
        this.$refs.CFCARef.download()
      },

      changeValidate(ref, toType) {
        //   ref:当前dom的ref
        //   toType:将要条状的操作类型
        this.operatingStatus = toType;
        this.$refs[ref].resetFields();
      },
      // 忘记密码-下一步 验证手机号码
      verifyMobile(formName) {
        var _this = this;
        _this.$refs[formName].validate(valid => {
          if (valid) {
            console.log('下一步 重置密码');
            //校验验证码
            verifyCode({
              code: _this.ruleFormForget.picCode,
              uuid: _this.imgUuid
            }).then(response => {
              if (response.errno == 0) {
                verifyCode({
                  code: _this.ruleFormForget.phonePicCode,
                  uuid: _this.phoneCodeUUID
                }).then(response => {
                  if (response.errno == 0) {
                    this.operatingStatus = 'reset';
                  } else {
                    alert('短信验证输入有误');
                  }
                });
              } else {
                alert('图形验证输入有误');
              }
            });
          }
        });
      },
      resetPassword(formName) {
        var _this = this;
        _this.$refs[formName].validate(valid => {
          if (valid) {
            //重置密码
            updatePwdByTel({
              pwd: _this.ruleFormReset.password1,
              tel: _this.ruleFormForget.phone,
              phoneCode: _this.ruleFormForget.phonePicCode,
              phoneCodeUUID: _this.phoneCodeUUID,
              ImgCode: _this.ruleFormForget.picCode,
              imgCodeUUID: _this.imgUuid
            }).then(response => {
              if (response.errno == 0) {
                _this.ruleFormForget = {
                  phone: '',
                  picCode: '',
                  phonePicCode: ''
                };
                _this.ruleFormReset = {
                  password1: '',
                  password2: ''
                };
                _this.dialogVisible = true;
              } else {
                // alert("页面已经过期")
              }
            });
          }
        });
      },
      // 忘记密码-获取验证码
      getCode(formName) {
        var _this = this;
        var valida_all = true;
        _this.$refs[formName].validateField(['phone', 'picCode'], valid => {
          if (valid) {
            valida_all = valida_all && false;
          } else {
            valida_all = valida_all && true;
          }
        });
        if (valida_all && _this.getCodeShow) {
          selectCountByTel({
            tel: _this.ruleFormForget.phone,
            scene: '3'
          }).then(res => {
            console.log(res);
            if (res.errno == 0) {
              getCode({
                phoneNum: _this.ruleFormForget.phone,
                scene: '8'
              }).then(res => {
                console.log('验证码：' + res.data.code);
                console.log('uuid:' + res.data.uuid);
                _this.phoneCodeUUID = res.data.uuid;
                if (res.data.ssm == '9') {
                  this.$message.error('今日获取验证码数量已到达上限');
                  return false;
                }
              });
              const TIME_COUNT = 60;
              if (!_this.timer) {
                _this.count = TIME_COUNT;
                _this.getCodeShow = false;
                _this.timer = setInterval(() => {
                  if (_this.count > 0 && _this.count <= TIME_COUNT) {
                    _this.count--;
                  } else {
                    _this.getCodeShow = true;
                    clearInterval(this.timer);
                    _this.timer = null;
                  }
                }, 1000);
              }
            }
          });
        }
      },
      getImgCode() {
        var _this = this;
        getWXImgCode().then(res => {
          // console.log(res)
          _this.imagebase = res.data.img;
          if (_this.imgUuid == res.data.uuid) {
            _this.offcache();
          }
          _this.imgUuid = res.data.uuid;
        });
      },

      offcache() {
        this.$notify.warning({
          title: '提示',
          message: '请前往浏览器internet选项>设置>internet临时文件：勾选:每次访问网页时（E）'
        });
      },
      // 去登录
      toLogin() {
        this.dialogVisible = false;
        this.operatingStatus = 'login';
      },
      // 登录
      login(formName) {
        var _this = this;
        var isLogin = false;
        if(process.env.VUE_APP_TurnOn !== 'false') {
          if(this.$refs.CFCARef.checkIsIE()){
            // 获取证书序列码
            if (!this.$refs.CFCARef.getCertInfo()) return;
            // 检测是否读取证书序列码
            if (!this.certInfo) return this.$message.info('未检测到证书信息');
          } else {
            this.$refs.CFCARef.getCertInfo()
            if (!this.certInfo) return ;
            //  this.$message.info('未检测到证书信息')
          }

          localStorage.setItem('certInfo', this.certInfo);
        }
      
        _this.$refs[formName].validate(valid => {
          if (valid) {
            if (!_this.resubmit) return false;

            _this.resubmit = false;
            // console.log(_this.ruleForm.nickname)
            // console.log(_this.ruleForm.password)

            login({
                loginName: _this.ruleForm.nickname,
                loginPwd: _this.ruleForm.password,
                code: _this.ruleForm.picCode,
                uuid: _this.imgUuid
              })
              .then(res => {
                if (res.errno == 0) {
                  let {
                    errmsg
                  } = res;
                  //   _this.$message.success('登录成功');
                  _this.setInof('logined', _this.ruleForm.nickname);
                  _this.setInof('loginSession', res.data);
                  getPermission();

                  localStorage.setItem('loginName', _this.ruleForm.nickname);
                  _this.ruleForm = {
                    nickname: '',
                    password: ''
                  };
                  isLogin = true;

                  var loginName = localStorage.getItem('loginName');

                  this.$notify.success({
                    title: '成功',
                    message: errmsg
                  });

                  selectAccountTypeByLoginName({
                    loginName: loginName
                  }).then(res => {
                    if (res.errno == 0) {
                      localStorage.setItem('accountType', res.data.accountType);
                      localStorage.setItem('userId', res.data.id);
                      localStorage.setItem('parentId', res.data.parentId);
                      localStorage.setItem('tel', res.data.tel);
                      localStorage.setItem('enterpriseId', res.data.enterpriseId);
                      localStorage.setItem('users', res.data.users);
                      localStorage.setItem('avatar', res.data.avatar);
                      localStorage.setItem('userInfo', JSON.stringify(res.data || {}));

                      if (errmsg === '登录成功,请尽快完善企业信息') {
                        localStorage.setItem('MenuType', 'BUY');
                        this.$router.push({
                          path: '/User/AttestInfo?t=' + Date.now()
                        });
                      } else {
                        _this.$router.push({
                          path: '/home/HomePage?t=' + Date.now()
                        });
                        // window.location.reload();
                      }
                    }
                    _this.resubmit = true;
                  });
                } else {
                  getImgCode(_this);
                  _this.resubmit = true;
                }
              })
              .catch(res => {
                _this.resubmit = true;
              });
          }
        });
      },

      setInof(key, value) {
        localStorage.setItem(key, value);
        this.setCookie(key, value); //存储localStorage的同时，也存储一个cookie来监听
      },

      setCookie(name, value, seconds) {
        seconds = seconds || 0; //seconds有值就直接赋值，没有为0
        var expires = '';
        if (seconds != 0) {
          //设置cookie生存时间
          var date = new Date();
          date.setTime(date.getTime() + seconds * 1000);
          expires = '; expires=' + date.toGMTString();
        }
        document.cookie = name + '=' + escape(value) + expires + '; path=/'; //转码并赋值
      },

      BrowserType() {
        var userAgent = window.navigator.userAgent;

        if (userAgent.indexOf('Trident') > -1) {
          //  this.$message({message: '请使用谷歌、火狐、edge或是360浏览器极速模式', duration: 0, showClose: true,   type: 'warning'});
          this.compatible = false;
        }

        if (userAgent.indexOf('Edg') > -1) {
          return 'Firefox';
        }

        if (userAgent.indexOf('Chrome') > -1) {
          return 'Chrome';
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  @hoverBlue: #188aff;

  .wrap {
    background: #fff;
    display: flex;
    flex-direction: column;
    // height: 100%;
    height: 100vh;

    .send_code {
      margin-left: 10px;
      background: darkorange;
      width: 180px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
    }

    .success-box {
      text-align: center;

      .el-icon-success {
        display: block;
        font-size: 50px;
        color: forestgreen;
      }

      .success-title {
        font-size: 18px;
        margin-top: 14px;
      }

      .success-btn {
        display: block;
        margin: 30px auto;
        width: 150px;
      }
    }

    .header {
      height: 40px;
      padding: 20px 60px;
      display: flex;
      align-items: center;
      box-shadow: 0px 2px 6px rgba(24, 138, 255, 0.2);
      z-index: 10;

      .logo-name {
        color: #333;
        font-size: 20px;
      }

      span {
        margin-left: 20px;
        font-size: 18px;
      }

      img {
        height: 100%;
      }

      .header-right {
        color: #a7b1c1;
        margin-left: auto;
        font-size: 15px;

        &:hover {
          color: @hoverBlue;
        }
      }
    }

    .mainCompatible {
      width: 100%;
      height: 1000px;

      .font-mainCompatible {
        margin-top: 40px;
        text-align: center;
        font-size: 30px;
      }
    }

    .main {
      flex: 1;
      background: #fff;

      .main-left {
        width: 60%;
        height: 100%;
        position: relative;

        .main-bg {
          flex: 1;
          height: 100%;

          img {
            height: 100%;
            // width: 100%;
            object-fit: fill;
          }
        }

        .login {
          position: absolute;
          top: 50%;
          right: -486px;
          margin-top: -237px;
          box-shadow: 0px 4px 30px rgba(24, 138, 255, 0.3);
          width: 426px;
          height: 475px;
          background: #fff;
          border-radius: 10px;
          padding: 43px 63px 63px;
          box-sizing: border-box;
          overflow: hidden;

          .login-title {
            position: relative;
            font-size: 22px;
            font-weight: bold;

            &::after {
              position: absolute;
              left: 0px;
              top: 18px;
              width: 100%;
              height: 10px;
              content: '';
              background: linear-gradient(to right, rgba(0, 169, 255, 0.3), rgba(10, 125, 239, 0.3));
            }
          }

          .login-main {
            padding: 30px 0 20px;

            .form-item {
              display: flex;
            }

            .login-button {
              border: 0;
              font-size: 16px;
              width: 100%;
              box-shadow: 0px 4px 10px rgba(24, 138, 255, 0.3);
              background-image: linear-gradient(to right, #00a9ff, #0a7def);
              margin-top: 10px;
              padding: 16px 0;
            }
          }

          .login-foot {
            .foot-item {
              cursor: pointer;
              color: #a7b1c1;
              font-size: 14px;

              &:hover {
                color: @hoverBlue;
              }
            }

            .foot-color {
              cursor: pointer;
              color: @hoverBlue;
            }
          }
        }
      }

      .main-right {
        min-width: 10px;
        flex: 1;
        height: 100%;
      }
    }
  }

  /deep/.el-input__inner {
    background: #f5f5fd;
    color: #333;
    border: none;
    padding: 0 46px;
  }

  /deep/.image .el-input__inner,
  /deep/.tel .el-input__inner {
    padding: 0 20px;
  }

  /deep/.el-form-item {
    padding-bottom: 10px;
  }
</style>
