<template>
  <div class="CFCA-container" :ref="id"></div>
</template>

<script>
import {nmCryptokit} from './nmCryptokit.Paperless.linkeasier.js';

/**
 * infoType 证书信息类型
    “SubjectDN”: 证书主题 DN；
    “SubjectCN”: 证书主题 CN；
    “SerialNumber”: 证书序列号；
    “Issuer”: 颁发者；
    “CertType”： 证书类型；
    “CSPName”: 证书对应的 CSP 名称；
    “CertContent”： 公钥证书的 Base64 编码；
    “ValidFrom”：证书有效期起始时间；
    “ValidTo”：证书有效期截止时间。

  * signType 签名类型
    "SignMsgPKCS1": SM2/RSA 签名
    "SignMsgPKCS7": 哈希算法(仅对RSA算法有效，SM2默认使用SM3哈希算法)
    "SignHashMsgPKCS1": PKCS#1哈希签名
    "SignBase64MsgPKCS7": PKCS#7Base64消息签名
    "SignHashMsgPKCS7Detached": PKCS#7分离式哈希签名
 */
export default {
  props: {
    id: {
      type: String,
      default: 'CFCAID'
    },
    infoType: {
      type: String,
      default: 'SerialNumber'
    },
    signType: {
      type: String,
      default: 'SignHashMsgPKCS7Detached'
    },
    signText: {
      type: String
    }
  },
  data() {
    return {
      browserUrl: '',
      CryptoAgent: null,
      isIE: false,
      key: {},
      CertInfoContent: {},
      selectCertResult: false, // 是否有证书
      InfoTypeID: '', // 证书序列号
      sourceHash: '', // 证书信息
      BrowserName: '' // 浏览器名称
    };
  },
  watch: {
    signText() {
      this.getSignInfo();
    }
  },
  created() {
    // console.log('9-23 4:43')
    if (process.env.VUE_APP_TurnOn == 'false') return console.log('关闭CFCA的监测');
    let that = this;

    // 获取版本信息
    function GetVersionOnClick() {
      let browser = BrowserInfo();
      let CryptoKit = new nmCryptokit(browser.name);

      CryptoKit.GetVersion()
        .then(function(response) {
          console.log('获取版本信息', response);
        })
        .catch(function(response) {
          console.error('获取版本信息失败', response);
        });
    }
    GetVersionOnClick();

    // 加载
    function LoadObj() {
      try {
        var extensionVer;
        var hostVer;

        // 判断浏览器
        var browser = BrowserInfo();
        if (
          'Edge' == browser.name ||
          'Edge(Chromium)' == browser.name ||
          ('Chrome' == browser.name && parseInt(browser.version) > 41) ||
          ('Firefox' == browser.name && parseInt(browser.version) > 52)
        ) {
          let CryptoKit = new nmCryptokit(browser.name);

          CryptoKit.init()
            .then(function() {
              return CryptoKit.getExtensionVersion();
            })
            .then(function(response) {
              extensionVer = response.result;
              return CryptoKit.getHostVersion();
            })
            .then(function(response) {
              hostVer = response.result;

              var extensionLevel = compareExtensionVersion(extensionVer);
              var hostLevel = compareVersion(hostVer, '3.4.0.1');
              SelectCertificate(CryptoKit);
              GetCertInfoOnClick(CryptoKit);
            })
            .catch(function(response) {
              // 区分chrome和360
              function checkChromeFor360() {
                var uas = navigator.userAgent.split(' '),
                  result = false;
                // 排除ua自带标志的双核浏览器, 余下chrome,chromium及360浏览器
                if (uas[uas.length - 1].indexOf('Safari') == -1) {
                  return result;
                }
                for (var key in navigator.plugins) {
                  // np-mswmp.dll文件在chromium及chrome未查询到
                  if (navigator.plugins[key].filename == 'np-mswmp.dll') {
                    return !result;
                  }
                }
                return result;
              }

              if (checkChromeFor360()) {
                that.$message({message: '请安装铝拍拍平台插件工具包后刷新页面再操作', duration: 0, showClose: true});
                that.browserUrl = process.env.VUE_APP_360URL
                that.$emit('getBrowser')
                // window.open(process.env.VUE_APP_360URL, '_self');
                return;
              }

              if (browser.name == 'Firefox') {
                that.$message({message: '请安装铝拍拍平台插件工具包后刷新页面再操作', duration: 0, showClose: true});
                that.browserUrl = process.env.VUE_APP_FireFoxURL
                that.$emit('getBrowser')
                // window.open(process.env.VUE_APP_FireFoxURL, '_self');
                return;
              }

              that.$message({message: '请安装铝拍拍平台插件工具包后刷新页面再操作', duration: 0, showClose: true});
              that.browserUrl = process.env.VUE_APP_URL
              that.$emit('getBrowser')
              // window.open(process.env.VUE_APP_URL, '_self');
              // }
            });
        } else {
          // alert("Unsupported browser!");
        }
      } catch (e) {
        // alert(e);
        return;
      }
    }

    // 判断浏览器
    function BrowserInfo() {
      var res = {
        name: '',
        version: ''
      };
      var reg;
      var userAgent = self.navigator.userAgent;

      if ((reg = /edge\/([\d\.]+)/i.exec(userAgent))) {
        res.name = 'Edge';
        res.version = reg[1];
      } else if ((reg = /edg\/([\d\.]+)/i.exec(userAgent))) {
        res.name = 'Edge(Chromium)';
        res.version = reg[1];
      } else if (/msie/i.test(userAgent)) {
        res.name = 'Internet Explorer';
        res.version = /msie ([\d\.]+)/i.exec(userAgent)[1];
      } else if (/Trident/i.test(userAgent)) {
        res.name = 'Internet Explorer';
        res.version = /rv:([\d\.]+)/i.exec(userAgent)[1];
      } else if (/chrome/i.test(userAgent)) {
        res.name = 'Chrome';
        res.version = /chrome\/([\d\.]+)/i.exec(userAgent)[1];
      } else if (/safari/i.test(userAgent)) {
        res.name = 'Safari';
        res.version = /version\/([\d\.]+)/i.exec(userAgent)[1];
      } else if (/firefox/i.test(userAgent)) {
        res.name = 'Firefox';
        res.version = /firefox\/([\d\.]+)/i.exec(userAgent)[1];
      }
      that.BrowserName = res;
      return res;
    }

    function compareExtensionVersion(version) {
      var browser = BrowserInfo();

      if ('Edge' == browser.name) {
        return compareVersion(version, '3.4.0.1');
      } else if ('Chrome' == browser.name) {
        return compareVersion(version, '3.4.0.1');
      } else if ('Firefox' == browser.name) {
        return compareVersion(version, '3.4.0.1');
      }
    }

    function compareVersion(version1, version2) {
      var v1Arr = version1.split('.');
      var v2Arr = version2.split('.');
      var len = Math.min(v1Arr.length, v2Arr.length);
      for (var i = 0; i < len; i++) {
        if (parseInt(v1Arr[i], 10) > parseInt(v2Arr[i], 10)) return 1;

        if (parseInt(v1Arr[i], 10) < parseInt(v2Arr[i], 10)) return -1;
      }

      if (len < v1Arr.length) {
        for (var j = len; j < v1Arr.length; j++) {
          if (parseInt(v1Arr[j], 10) != 0) return 1;
        }
      }

      if (len < v2Arr.length) {
        for (var j = len; j < v2Arr.length; j++) {
          if (parseInt(v2Arr[j], 10) != 0) return -1;
        }
      }

      return 0;
    }

    // 查看是否有证书
    function SelectCertificate(CryptoKit) {
      var subjectDNFilter = '';
      var issuerDNFilter = '';
      var serialNumFilter = '';
      var cspNameList = '';

      CryptoKit.SelectCertificate(subjectDNFilter, issuerDNFilter, serialNumFilter, cspNameList)
        .then(function(response) {
          that.selectCertResult = response.result;
        })
        .catch(function(response) {
          that.$message({message: '证书库中没有可用的证书 (0xa0071001)', showClose: true});
        });
    }

    // 获取证书信息
    function GetCertInfoOnClick(CryptoKit) {
      var InfoTypeID = '';
      var InfoContent = '';

      // certificate information identifier
      InfoTypeID = 'SerialNumber';
      // console.log(response.result,'查看证书内容')
      CryptoKit.GetSignCertInfo(InfoTypeID)
        .then(function(response) {
          that.InfoTypeID = response.result;
        })
        .catch(function(response) {
          // console.log('查看证书内容失败')
          // ShowErrorInfo(response);
        });
    }

    if (navigator.appName.indexOf('Internet') >= 0 || navigator.appVersion.indexOf('Trident') >= 0) return;

    LoadObj();
  },
  mounted() {
    this.init();
  },
  methods: {
    // 下载对应的浏览器插件
    download(){
      window.open(this.browserUrl,'_self')
    },

    // 从初始化插件
    init() {
      let el = this.$refs[this.id];

      // 判断是否 IE 浏览器
      if (navigator.appName.indexOf('Internet') >= 0 || navigator.appVersion.indexOf('Trident') >= 0) {
        this.isIE = true;

        if (window.navigator.cpuClass == 'x86') {
          el.innerHTML = `<object id="${this.id}" codebase="CryptoKit.Paperless.x86.cab" classid="clsid:B64B695B-348D-400D-8D58-9AAB1DA5851A" ></object>`;
          // IE 临时版本
          // el.innerHTML = `<object id="${this.id}" codebase="CryptoKit.Paperless.linkeasier.x86.cab" classid="clsid:827742A9-227C-4EEA-A105-DB8C9C2B8A94" ></object>`;
        } else {
          el.innerHTML = `<object id="${this.id}" codebase="CryptoKit.Paperless.x64.cab" classid="clsid:8BF7E683-630E-4B59-9E61-C996B671EBDF" ></object>`;
          // IE 临时版本
          // el.innerHTML = `<object id="${this.id}" codebase="CryptoKit.Paperless.linkeasier.x64.cab" classid="clsid:790AF42E-168C-46BA-BA72-97454796747E" ></object>`;
        }
      } else {
        this.isIE = false;
        return;
        // el.innerHTML = `<embed id="${this.id}" type="application/npCryptoKit.Paperless.x86" style="height: 0px; width: 0px">`;
      }
      this.CryptoAgent = document.getElementById(this.id);

      if (!this.checkIsIE()) return;

      this.selectCert();
    },
    checkIsIE() {
      if (!this.isIE) {
        return false;
      } else {
        return true;
      }
    },
    // 选择证书
    selectCert() {
      if (!this.checkIsIE()) {
        return false;
      }
      try {
        let subjectDN = '', // 证书主题DN
          issuerDN = '', // 颁发者主题DN
          serialNum = '', // 证书序列号
          cspName = '', // CSP名称(多家CSP用"||"分隔)
          isSelectCert = ''; // 是否选中证书

        // 选择证书
        isSelectCert = this.CryptoAgent.SelectCertificate(subjectDN, issuerDN, serialNum, cspName);

        // Opera浏览器，NPAPI函数执行结果为false时，不能触发异常，需要自己判断返回值。
        if (!isSelectCert) {
          let errorDesc = this.CryptoAgent.GetLastErrorDesc();
          this.$message.info(errorDesc);
          return false;
        }

        return true;
      } catch (e) {
        let errorDesc = this.CryptoAgent.GetLastErrorDesc();
        this.$message.info(errorDesc);
        return false;
      }
    },
    // 获取证书系列号
    getCertInfo() {
      if (!this.checkIsIE()) {
        let CryptoKit = new nmCryptokit(this.BrowserName.name);
        let that = this;

        // 初始化证书的序列号和存在
        that.selectCertResult = false;
        that.InfoTypeID = false;

        function selectCertificate(CryptoKit) {
          var subjectDNFilter = '';
          var issuerDNFilter = '';
          var serialNumFilter = '';
          var cspNameList = '';

          return CryptoKit.SelectCertificate(subjectDNFilter, issuerDNFilter, serialNumFilter, cspNameList)
            .then(function(response) {
              // console.log(response.result,'有证书')
              that.selectCertResult = response.result;
              // that.$message({message:'有可用的证书',showClose:true,type: 'success'});
            })
            .catch(function(response) {
              that.selectCertResult = false;
              that.$message({message: '未检测到证书信息', showClose: true});
              // console.log('没有证书')
            });
        }

        function getCertInfoOnClick(CryptoKit) {
          // console.log(response.result,'查看证书序号')
          return CryptoKit.GetSignCertInfo('SerialNumber')
            .then(function(response) {
              that.InfoTypeID = response.result;
              that.$emit('getCertInfo', response.result);
              console.log(response.result, '序列号');
              // that.$message({message:'测试获取证书内容成功',showClose:true,type: 'success'});
            })
            .catch(function(response) {
              that.InfoTypeID = false;
              // that.$message({message:'测试获取证书内容失败',showClose:true});
              // console.log('查看证书内容失败')
            });
        }
        async function waitfun() {
          await selectCertificate(CryptoKit);
          if (!that.selectCertResult) return;
          getCertInfoOnClick(CryptoKit);
        }

        waitfun(CryptoKit);
        return;
      }

      try {
        if (!this.selectCert()) return;

        let InfoTypeID = this.infoType, //信息类型标识
          InfoContent = ''; // 证书信息

        // 读取证书信息
        InfoContent = this.CryptoAgent.GetSignCertInfo(InfoTypeID);
        this.$emit('getCertInfo', InfoContent);
        return true;
      } catch (err) {
        let errorDesc = this.CryptoAgent.GetLastErrorDesc();
        this.$message.info(errorDesc);
        this.$emit('getCertInfo');
        return false;
      }
    },
    // 获取证书签名信息
    getSignInfo() {
      if (!this.checkIsIE()) {
        let that = this;
        that.sourceHash = '';
        function signOnClick(sourceHashData) {
          let browser = that.BrowserName;
          let CryptoKit = new nmCryptokit(browser.name);
          // ShowErrorInfo(response)
          return CryptoKit.SignHashMsgPKCS7Detached(sourceHashData, 'SHA-256', false)
            .then(function(response) {
              that.sourceHash = response.result;
            })
            .catch(function(response) {
              // that.$message({message:'获取证书签名信息失败',showClose:true});
              that.$emit('getSignInfo');
            });
        }

        async function waitfun() {
          await signOnClick(that.signText);
          if (!that.sourceHash) return;
          that.$emit('getSignInfo', that.sourceHash);
        }
        waitfun();
        return;
      }
      try {
        if (!this.selectCert()) return;

        let sourceHashData = this.signText, // 签名名原文
          signature = ''; // 签名结果

        // 签名
        switch (this.signType) {
          case 'SignHashMsgPKCS7Detached':
            signature = this.CryptoAgent[this.signType](sourceHashData, 'SHA-256');
            break;
          case 'SignMsgPKCS7':
            signature = this.CryptoAgent[this.signType](sourceHashData, 'SHA-256', true);
            break;
        }

        if (!signature) {
          let errorDesc = this.CryptoAgent.GetLastErrorDesc();
          this.$message.info(errorDesc);
          this.$emit('getSignInfo');
        }

        this.$emit('getSignInfo', signature);
      } catch (e) {
        let errorDesc = this.CryptoAgent.GetLastErrorDesc();
        this.$message.info(errorDesc);
        this.$emit('getSignInfo');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.CFCA-container {
  display: none;
}
</style>
