import server from '@/api';

//登录
export function login(param) {
  return server.login.login(param);
}

//验证手机号码是否被使用
export function selectCountByTel(param) {
  return server.login.selectCountByTel(param);
}

//根据手机号码修改密码
export function updatePwdByTel(param) {
  return server.login.updatePwdByTel(param);
}

//校验验证码
export function verifyCode(param) {
  return server.login.verifyCode(param);
}

//获取验证码
export function getCode(param) {
  return server.login.getCode(param);
}

//获取图形验证码
export function getWXImgCode(param) {
  return server.login.getImgCode(param);
}

// 获取用户权限
export function getPermission() {
  //   server.permission.getPermission().then(res => {
  //     if (res.errno === 0) {
  //       if (res.data) {
  //         let userPermission = JSON.stringify(res.data);
  //         localStorage.setItem('userPermission', userPermission);
  //         console.log(JSON.parse(localStorage.getItem('userPermission')), 999);
  //       } else {
  //       }
  //     }
  //   });
}

//查询账号类型
export function selectAccountTypeByLoginName(param) {
  return server.users.selectAccountTypeByLoginName(param);
}

// 查询手机号和UKey是否绑定
export function validateUKey(params) {
  return server.login.validateUKey({params});
}

// 根据手机号码修改密码（新版去掉图形验证）
export function updatePwdByTelNew(param) {
  return server.login.updatePwdByTelNew(param);
}
